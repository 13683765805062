import classNames from "classname";
import SectionBoxData from "../variables/variables.json";
import Image from "next/image";
import TranslatedMessage from "./TranslatedMessage";
import { useIntl } from "react-intl";
import Link from "next/link";
import { useMemo } from "react";

const SectionBox = (props) => {
	const intl = useIntl();
	let section = props.section;

	const translatedUrls = useMemo(() => {
		return SectionBoxData[section].column.map((item) =>
			item.url ? intl.formatMessage({ id: item.url }) : null,
		);
	}, [section, intl]);

	return (
		<>
			<div
				className={classNames("pd-section__box__container row center-xs", {
					"pd-section__box__container--round": props.round,
					"pd-section__box__container--light": props.light,
					"pd-section__box__container--white": props.white,
					"pd-section__box__container--box": props.box,
				})}
			>
				{SectionBoxData[section].column.map((item, index) => {
					return (
						<div className={item.class} key={index}>
							<div
								className={classNames("pd-section__box", {
									"pd-section__box--with-link": item.url,
									"pd-section__box--center": props.center,
								})}
							>
								{item.url && (
									<Link
										className="pd-section__box__full-link"
										href={item.url}
										aria-label={translatedUrls[index]} // Użycie zapamiętanego tłumaczenia
										tabIndex="0"
									></Link>
								)}
								<div className="pd-section__box__image">
									<div
										className={classNames("pd-section__box__image__img", {
											"pd-wcag--no-visible": item.imageAlt.available,
										})}
									>
										<Image unoptimized 
											src={item.image.url}
											width={item.image.width}
											height={item.image.height}
											alt=""
											style={{
												maxWidth: "100%",
												height: "auto",
											}}
										/>
									</div>
									{item.imageAlt.available && (
										<div className="pd-section__box__image__img pd-wcag--visible">
											<Image unoptimized 
												src={item.imageAlt.url}
												width={item.image.width}
												height={item.image.height}
												alt=""
											/>
										</div>
									)}
								</div>
								<p className="pd-section__box__title">
									<TranslatedMessage id={item.title} />
									{props.link && <span className="chevron right"></span>}
								</p>
							</div>
						</div>
					);
				})}
			</div>
		</>
	);
};

export default SectionBox;
